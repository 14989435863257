import React from "react";
import "./title.scss";

const Title = () => {
  return (
    <div class="title">
      <p>Lucile Vacquié</p>
    </div>
  );
};

export default Title;
