import React from "react";
import Accordion from "../components/accordion/education";

const Education = () => {
  return (
    <>
      <Accordion />
    </>
  );
};

export default Education;
